/* GENERALES */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  background: #FFF;
  font-family: 'Muli', sans-serif;
  font-family: "Montserrat", sans-serif;
  background-size: cover;
  background-attachment: fixed;
  /* background-position: center center; */
  background-image: url('/images/fondo.png');
}

.body-intern{
  background-color: #FFF;
  background-image: none;
}

.no-scroll{
  overflow: hidden !important;
}

.mt-25{
  margin-top: 6.5rem;
}

/* .body-intern .home { */
.body-intern{
  background-color: #FFF;
  /* background-position: top; */
  background-image: url('/images/fondoInterno.png');
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  cursor: pointer;
}

.border-hr{
  border-color: #298194;
}

.text-label{
  color: #9EB9CC;
}

select{
  text-align-last: center;
}

.form span{
  /* color: #D4535B !important; */
  font-size: 0.85rem !important;
  letter-spacing: 0.028em !important;
}

.footer img{
  width: 105px;
  height: 28px;
}
/* MEDIAS QUERYS */

@media (max-width: 640px) {
  body {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url('/images/fondo_mb.png');
    /* overflow: hidden; */
  }

  /* .body-intern .home { */
  .body-intern {
    background-size: cover;
    background-image: url('/images/fondoInterno_mb.png');
    background-attachment: fixed;
    background-position: initial;
  }

  .text-initial {
    /* margin-top: 30rem ;/* 20rem; 86%;
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: hidden;*/
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
    position: absolute;
    /* bottom: 2.8rem; */
    bottom: 8%;
  }

  .modal{
    width: 88% !important;
    /* background-color: gray !important; */
  }

  .modal > div{
    background-image: url('/images/modal_bg_mb.png') !important;
    background-repeat: no-repeat !important;
    background-size: 100% !important;
  }

  .modalInt{
    width: 88% !important;
    /* background-color: lightblue !important; */
  }

  .modalInt > div{
    background-image: url('/images/modal_int_mb.png') !important;
    background-repeat: no-repeat !important;
    background-size: 100% !important;
  }
}

@media only screen and (min-width: 640px) {

  .text-initial{
    margin-top: 12rem;
    margin-left: 5rem;
    text-align: center;
  }

}

@media only screen and (min-width: 768px) {

  .text-initial{
    margin-top: 14rem;
    margin-left: 0rem;
    text-align: center;
  }

  .modal{
    width: 560px !important;
    /* background-color: saddlebrown !important; */
  }
}


