.option p{
  font-size: 1.55rem;
  line-height: 1.55rem;
}

.option span{
  color: #9BB8CA !important;
  letter-spacing: 0.10em;
  line-height: 1.3em;
  font-size: 0.80rem;
}