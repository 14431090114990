
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  /* width: 550px; */
  width: 600px;
  height: 560px; /* REVISAR SCREEN */
  background-color: white;
  border-radius: 12px;
  transform: translate(-50%, -50%);
  
  overflow: auto;
}

.modal > div{
  background-image: url('/images/modal_bg.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

.modal:focus{
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0, 0.5);
  z-index: 9999;
}

.modal .close{
  position: absolute;
  right: -0rem;
  top: -0rem;
}

@media (max-width: 440px) {
  .txt-intro {
    /* background: #2a76919e; */
  }
}