
.modalInt {
  position: absolute;
  top: 50%;
  left: 50%;
  /* width: 550px; */
  width: 600px;
  height: 540px; /* REVISAR SCREEN 
  background-color: white;*/
  transform: translate(-50%, -50%);
  overflow: auto;
}

.modalInt:focus{
  outline: none;
}

.btn-logout{
  background-color: #408dc0;
  color: white;
  /* border: 1px solid #0c3c66; */
  border-radius: 6px;
  cursor: pointer;
  padding: 0 10px;
}

.modalCourse {
  position: absolute;
  top: 50%;
  left: 50%;
  /* width: 550px; */
  width: 84%;
  height: 86%;
  max-width: 1245px;
  /* height: 72%; /* REVISAR SCREEN  */
  /*background-color: white;*/
  transform: translate(-50%, -50%);
  /* overflow: auto; */
}

.modalInt > div{
  background-image: url('/images/modal_int.png');
  background-repeat: no-repeat;
  background-size: 100%;
  /* height: 300px; */
}

.modalInt .card-img{
  width: 70px;
  height: 70px;
  /* background-color: #F3F6F9; */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
}

.modalInt p{
  color: #99969D;
}

.modalCourse .close{
  position: absolute;
  right: -1rem;
  top: -1rem;
}